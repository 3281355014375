import React from 'react'
import Link from 'gatsby-link'
import headerImage from '../images/ecommerce/magento-demos@2x.png';
import logoWordPress from '../images/web/wordpress-white.png';

import './css/aplicacoes.scss'
import Layout from "../components/layout";
import SEO from "../components/seo";

import Browser from "../images/webPage/browser.png";
import SiteDemo from "../images/webPage/browser2.png";
import PhoneDemo from "../images/ecommerce/magento-demos.png";
import Laptop from "../images/aplicativos/laptop-mockup-11.png";

const Aplicacoes = () => (
    <Layout>
        <SEO title="Aplicações" description="Soluções que irão te acompanhar seja aplicações web ou desktop." keywords={[`aplicações`, `aplicações web`, `aplicações desktop`, `desktop`, `desenvolvimento software`]} />
        <div className="main" role="main">
            <div className="ui-hero hero-lg hero-center hero-bg ui-curve hero-svg-layer-1 ui-gradient-blue">
                <div className="container pb-4">
                    <div className="row pt-2 pb-8">
                        <div className="col-sm-12" data-vertical_center="true" data-vertical_offset="16">
                            <h1 className="heading animate" data-show="fade-in-up-big" data-delay="100">
                                <span className="text-blue-sc">Aplicações Web e Desktop</span> Feitos Pensado no
                                <span className="text-blue-sc"> seu Negócio</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section intro-image pt-0">
                <img src={Browser} data-uhd data-max_width="1000"
                     className="shadow-xxl responsive-on-lg intro-img"/>
            </div>


            <div id="how-it-works" className="section bg-white">
                <div className="container">
                    <div className="section-heading center">
                        <h2 className="heading text-dark-gray">
                            Feitos para te Atender
                        </h2>
                        <p className="paragraph">
                            Soluções que irão te acompanhar seja aplicações web ou desktop.
                        </p>
                    </div>

                    <div className="ui-showcase-blocks ui-steps">
                        <div className="step-wrapper">
                            <span className="step-number ui-gradient-blue"></span>
                            <div className="row">
                                <div className="col-md-6" data-vertical_center="true">
                                    <h4 className="heading text-dark-gray">
                                        Do Seu Tamanho
                                    </h4>
                                    <p className="paragraph">
                                        Nada de aplicações padrões. Nós desenvolvemos tudo do zero,
                                        com identidade e com a sua cara.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <img className="responsive-on-xs" src={SiteDemo} data-max_width="464"/>
                                </div>
                            </div>
                        </div>
                        <div className="step-wrapper">
                            <span className="step-number ui-gradient-blue"></span>
                            <div className="row">
                                <div className="col-md-6" data-vertical_center="true">
                                    <h4 className="heading text-dark-gray">
                                        O Dinamismo que Você Precisa!
                                    </h4>
                                    <p className="paragraph">
                                        Sua aplicação responsiva com um aplicativo mobile, junto de uma aplicação web completa.
                                        Tudo para você manter o controle e ganhar tempo administrando seu negócio.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <img className="responsive-on-xs" src={PhoneDemo} data-uhd
                                         alt="Applify - App Landing HTML Template" data-max_width="445"/>
                                </div>
                            </div>
                        </div>
                        <div className="step-wrapper">
                            <span className="step-number ui-gradient-blue"></span>
                            <div className="row">
                                <div className="col-md-6" data-vertical_center="true">
                                    <h4 className="heading text-dark-gray">
                                        A Integração que Você Precisa
                                    </h4>
                                    <p className="paragraph">
                                        Integração ao ERP de sua empresa com um painel completo para o controle de suas vendas e muito mais.

                                    </p>
                                </div>
                                <div className="col-sm-6">
                                    <img className="responsive-on-xs" src={Laptop}
                                         data-uhd
                                         alt="Applify - App Landing HTML Template" data-max_width="451"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="features" className="section bg-light">
                <div className="container ui-icon-blocks ui-blocks-h icons-lg">
                    <div className="section-heading center">
                        <h2 className="heading text-indigo">
                            O Básico Que Vai Além
                        </h2>
                        <p>
                            Recursos básicos de ponta que podemos chamar de avançados
                        </p>

                    </div>

                    <div className="row">
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle fa fa-shield ui-gradient-dark"></div>
                            <h4>Segurança</h4>
                            <p>
                                Não basta ser funcional e bonito. A System Code preza pela segurança
                                de suas aplicações.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle icon-earphones-alt ui-gradient-peach"></div>
                            <h4>Suporte</h4>
                            <p>
                                Equipe de suporte especializada que estarão ao seu lado quando precisar.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle icon-settings ui-gradient-blue"></div>
                            <h4>Consultoria</h4>
                            <p>
                                Consulturia completa para criação da sua aplicação. Para deixa-lo como você precisa.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default Aplicacoes
